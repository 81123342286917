<template>
  <span class="code" @click="getCode()">{{ codeTxt }}</span>
</template>

<script>
export default {
  name: 'sCode',
  props: ['phone'],
  data() {
    return {
      codeTxt: '发送验证码'
    };
  },
  created() {
    // 在组件实例处理完所有与状态相关的选项后调用。
  },
  mounted() {
    // 在组件被挂载之后调用。
  },
  methods: {
    // 获取验证码
    async getCode() {
      if (this.codeTxt != '发送验证码' && this.codeTxt != '重新发送') return;
      if (!this.phone) {
        this.$emit('getCode', 0);
        return;
      }
      const ret = await this.$y_ajax('api/code/sms', { phone: this.phone });
      if (ret && ret.code == 200) {
        let t = 60;
        let st = setInterval(() => {
          t--;
          if (t == 0) {
            clearInterval(st);
            this.codeTxt = '重新发送';
            return;
          }
          this.codeTxt = '请稍后(' + t + 's)';
        }, 1000);
      } else {
        this.$y_msg(ret.msg);
      }
    }
  },
  computed: {
    // 计算属性
  },
  watch: {
    // 监听
  }
};
</script>

<style lang="less" scoped>
.code {
  word-break: keep-all;
  margin-left: 15px;
  cursor: pointer;
}
</style>
