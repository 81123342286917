<template>
    <div class="head">
        <div class="header">
            <div class="top-wrap">
                <el-row class="w1200">
                    <!-- <el-popover popper-class="city-box" width="400" :visible-arrow="false" placement="bottom" trigger="click" v-model="cityInfo.show">
            <span slot>
              <el-tabs type="border-card" class="city" v-model="tags">
                <el-tab-pane label="省份" name="1">
                  <span class="name" @click="setProvice(index)" :class="{ active: cityIndex == index }" v-for="(m, index) in province">{{ m }}</span>
                </el-tab-pane>
                <el-tab-pane label="城市" name="2">
                  <span class="name" @click="setCity(m)" v-for="(m, index) in cityList[cityIndex]" :class="{ active: cityInfo.city == m }">{{ m }}</span>
                </el-tab-pane>
              </el-tabs>
            </span>
            <router-link to="" slot="reference" class="link-btn city-btn" @click="cityInfo.show = !cityInfo.show">
              <span>
                {{ cityInfo.val || '全国' }}
                <i class="el-icon-arrow-down"></i>
              </span>
            </router-link>
          </el-popover> -->
                    <router-link to="" class="link-btn nopc-app"><span @click="openApp()">下载APP</span></router-link>
                    <router-link to="/search"><i class="el-icon-search"></i></router-link>
                    <router-link to="" class="link-btn"><span @click="$y_msg('请下载APP发布')">发布小目标</span></router-link>
                    <router-link to="" class="link-btn" v-if="!s_getUser"><span @click="showLoginModal()">注册/登录</span></router-link>
                    <span v-else>
                        <router-link to="/my" class="link-btn">
                            <!-- <span>Hi {{ s_getUser.name }}</span> -->
                            <span>个人中心</span>
                        </router-link>
                        <router-link to="" class="link-btn"><span @click="logout">退出</span></router-link>
                    </span>

                    <!-- <router-link to="" class="link-btn"><span @click="openApp()">手机版</span></router-link>
          <el-popover placement="bottom" width="200" trigger="hover">
            <span slot><el-image class="" :src="gzhImg"></el-image></span>
            <router-link to="" slot="reference" class="link-btn"><span>关注热浪</span></router-link>
          </el-popover> -->
                    <!-- <router-link to="" class="link-btn"><span>帮助中心</span></router-link> -->
                </el-row>
            </div>
            <div class="nav-wrap" :class="{ 'fix-nav': top > 50 }">
                <el-row class="wrapper w1200">
                    <div class="left-wrap">
                        <router-link to="" class="logo"><img @click="_home()" src="../../assets/logo.png" alt="" /></router-link>
                        <ul class="nav">
                            <li v-for="(m, index) in navList" :key="m.id" :class="[navIndex == index ? 'is-active' : '']">
                                <router-link :to="m.path" v-if="m.id != 4" @click="navIndex = index">
                                    <span>{{ m.name }}</span>
                                </router-link>
                                <a :href="m.path" target="_blank" v-else><span>{{ m.name }}</span></a>
                            </li>
                        </ul>
                    </div>
                    <el-button round class="app-btn" @click="openApp()">下载APP</el-button>
                    <!-- <el-button round class="app-btn">先付发布</el-button> -->
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        province,
        city
    } from "@utils/area";
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        name: "myHeader",
        components: {},
        data() {
            // 这里存放数据
            return {
                province: province,
                cityList: city,
                cityIndex: 0,
                logined: true,
                navIndex: 0, // 一级导航
                top: 0,
                tags: "1",
                config: {}, // 配置
                cityInfo: {
                    show: false,
                    val: "",
                },
                navList: [{
                        id: 1,
                        name: "推荐",
                        path: "/index",
                    },
                    // {
                    //   id: 2,
                    //   name: "陷阱",
                    //   path: "/trap",
                    // },
                    {
                        id: 4,
                        name: "热浪智能",
                        path: "http://a.relang.tv",
                    },
                    // {
                    //     id: 4,
                    //     name: "小目标",
                    //     path: "/flag",
                    // },
                    {
                        id: 5,
                        name: "服务",
                        path: "/service",
                    },
                    {
                        id: 3,
                        name: "关于热浪",
                        path: "/aboutMe",
                    },
                ],
            };
        },
        mounted() {
            let _this = this;
            let cityInfo = _this.$y_getKey("cityInfo");
            if (cityInfo) {
                _this.cityIndex = cityInfo.proIndex;
                _this.cityInfo.val = cityInfo.city;
            }
            this.config = this.$y_getKey("config");
            _this.$nextTick(function() {
                // 根据滚动高度显示头部
                window.onscroll = function(e) {
                    _this.top = document.documentElement.scrollTop;
                };
            });
        },
        // 监听属性 类似于data概念
        computed: {
            ...mapGetters(["s_getUser"]),
        },
        // 方法集合
        methods: {
            ...mapMutations(["s_setUser", "s_setLoginModal", "s_setCity"]),

            showLoginModal() {
                this.s_setLoginModal(1);
            },
            // 选择省份
            setProvice(index) {
                this.cityIndex = index;
                this.tags = "2";
            },
            // 选择城市
            setCity(m, index) {
                if (m == "全国") {
                    m = "";
                }
                m = m.replace("市", "");
                this.cityInfo.val = m;
                this.cityInfo.show = false;
                this.s_setCity({
                    proIndex: this.cityIndex,
                    city: m,
                });
            },
            // 退出登录
            async logout() {
                let t = Math.ceil(new Date().getTime() / 1000);
                await this.$y_ajax("api/login/setOnline", {
                    state: 0,
                    user_id: this.$y_getKey("userid"),
                    logout: t,
                });
                this.$y_rmKey("userid");
                this.$y_rmKey("token");
                this.s_setUser("");
            },
        },
        watch: {
            $route(to, from) {
                // 路径判断
                const p = to.path;
                const o = {
                    "/index": 0,
                    // "/trap": 1,
                    "/flag": 1,
                    "/service": 2,
                    "/aboutMe": 3,
                };
                this.navIndex = o[p];
            },
        },
    };
</script>
<style scoped lang="less">
    // 一级导航
    .header {
        position: relative;
        z-index: 3;
        background: #fff;

        // 顶部小指引
        .top-wrap {
            background: #f5f5f5;
            text-align: right;

            .city-btn {
                float: left;
            }

            .el-icon-search {
                padding: 10px;
                display: inline-block;
                font-size: 20px;
                cursor: pointer;
            }

            .link-btn {
                padding: 10px;
                display: inline-block;
            }
        }

        .nav-wrap {
            border-bottom: 1px solid #eee;

            &.fix-nav {
                position: fixed;
                width: 100%;
                background: #fff;
                box-shadow: 0 2px 5px #ddd;
                border-bottom: 0;
                top: 0;
            }
        }

        //分类栏
        .wrapper {
            display: flex;
            align-items: center;

            .left-wrap {
                display: flex;
                flex: 1;
                line-height: 70px;
            }

            .logo {
                display: inline-block;
                height: 70px;
                display: flex;
                align-items: center;
                margin-right: 20px;

                img {
                    height: 60%;
                    border-radius: 7px;
                }
            }

            // 大导航
            .nav {
                border: 0;
                font-size: 0;

                li {
                    display: inline-block;
                    padding: 0;
                    color: #909399;
                    cursor: pointer;
                    border-bottom: 2px solid transparent;
                }

                .is-active {
                    color: #49C265;
                    font-weight: bold;
                }

                span {
                    padding: 0 30px;
                    display: block;
                    font-size: 18px;
                    text-align: center;
                    text-decoration: none;
                }
            }

            .app-btn {
                background: #49C265;
                border: 0;
                color: #fff;
            }
        }
    }

    .city-box {
        width: 400px;
    }

    .city {
        .name {
            margin-right: 15px;
            margin-bottom: 15px;
            display: inline-block;
            cursor: pointer;

            &:hover {
                color: #49C265;
            }

            &.active {
                color: #49C265;
            }
        }
    }

    .nopc-app {
        display: none !important;
    }

    /* 移动端 */
    @media (max-width: 600px) {
        .logo {
            display: none !important;
        }

        .app-btn {
            margin-right: 15px;
        }

        .nopc-app {
            float: left;
            display: inline-block !important;
        }

        .header .wrapper {
            display: block;

            .nav {
                display: -webkit-box;
                overflow-x: auto;
                width: 100%;
                line-height: 40px;

                span {
                    width: auto;
                    padding: 0 10px;
                }
            }

            .app-btn {
                display: none;
            }
        }
    }
</style>