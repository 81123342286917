import axios from 'axios';
import errImg from '@assets/logo.png';
import txImg from '@assets/touxiang.png';

import {
    Message,
    Loading,
    MessageBox
} from 'element-ui';
import {
    Base64
} from 'js-base64';
import JSEncrypt from "jsencrypt";

// const fondfell_url = 'http://rl.relang.tv'; // 正式
// const fondfell_url = 'http://www.relang.tv'; // 正式
// const fondfell_url = 'http://relang-dev.nihaozaima.com'; // 测试
const fondfell_url = ''; // 服务器地址
const base_url = fondfell_url + '/'; // 请求
const share_url = fondfell_url + '/a.php'; // 分享路径 | app下载地址
const home_url = '#/index'
const imgurl = fondfell_url; // 图片地址
const pubKey = `-----BEGIN PUBLIC KEY-----MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAs7cCHABN8hwCajVcp4IFM3JonfuAKPfyFVaGGMKzfFNyp7CgZjLXisQ2p7NprVWszYcGVpTvSCeoEUhjb4t41T36/I5nN3BpbFPqCOxVLjiozkx/Cpyo5LrlQEWLCOD6by7WMhK2NPKyzIzbpeYlQu3iqbHWmNBF2lqlJ05RL2ftozu7y+qmjeCToR8MOAXzxABe9owWKJrKjbjXNaWHwU4vdUY9UWgZZSKHEFORkl1vT1FOxa0iu74vKkn+F6AIm964gGnYtLkaE0ff6GpEreRNM6jiPyY3ApEl9lv5MMjba32mkfAQzYhiutWbTTJ/SCW0iR2/lBxeVkj37krIAdJY5YyCSkzicSD+iObXaH0252bp4Usmzi/fS+6J+m0qEL5XgHo/W/KymZHuBosTr1Fx6j2Po9wa2t4zhQe4YYHEwictqWx1ZjR8GtO0VgCGc5OqmJ/FY2TgKESE+iSXLgd0KDbuGvF9gvmdorICKgmJkgPVxdmnDw3uFCjmv0ZnXpptKJ0XNiyrcTc05gOA1fAExbY1d1FBXN92zxbGdo8xyzsIJ9UFQK93ZEMRDxjEpp8lPlW+95pkXqjrJlhQQaW9MhQ/6ALSA4151TjzfWRVcisrodmqbT/2ByD7TKEAtmZIm7p2AzpKQ2Jpc8JH2xP6JVjI+/g9QuxlVKnakvkCAwEAAQ==-----END PUBLIC KEY-----`;

// 公众号图片
const gzhImg =
    "http://zaima.oss-accelerate.aliyuncs.com/disk/uploads/image/20220916/ee1c1dc0be02e57675d6f29c037afe30b44b7808.jpg";
const INDEXTYPE = 0; // 0不添加index.php 1添加

// 需校验登录的url
const checkLogin = (url) => {
    url = url.toLowerCase();
    let obj = {
        'api/flag/like': 1, // 点赞flag
        'api/flag/supervise': 1, // 围观flag
        'api/flag/comment': 1, // 评论flag
        'api/activity/join': 1, // 参与活动
        'home/dymanic/thumbsup': 1, // 点赞动态,
        'home/dymanic/comment': 1, // 评论动态,
        'api/nihao/thumbsup': 1, // 评论动态,
        'api/nihao/comment': 1, // 评论动态,
        'api/nihao/lover': 1, // 评论动态,
    }
    return obj[url] && !getKey('userid') ? false : true;
}

//接口处理
const dealUrl = (url) => {
    if (getKey('token')) {
        let obj = {
            'api/Flag/flagsInfoul': 'api/Flag/flagsInfo',
            'api/activity/infoul': 'api/activity/info',
        }
        if (obj[url]) {
            url = obj[url]
        }
    }
    return url;
}

/* 无需导出的方法 */
// 接口处理 return url
const rurl = (urls) => {
    let url = urls.toLowerCase(); // 转为小写
    let f_url;
    if (url.indexOf('http') != -1) return urls;
    if (url.indexOf('.php') != -1) { // 原生
        f_url = base_url + 'apis/';
    } else if (url.indexOf('home/') != -1) { // tp3
        f_url = base_url + 'apis/index.php/';
    } else if (url.indexOf('api/') != -1) { // tp5
        f_url = base_url + 'index.php/';
    } else { // 后端封装
        f_url = base_url + 'apis/index.php?'
    }
    if (INDEXTYPE == 0) {
        f_url = f_url.replace('/index.php', '');
    }
    return f_url + urls;
}
// 提示 success/warning/info/error
const _msg = (msg, t) => {
    if (!msg) return;
    Message({
        message: msg,
        type: t || 'info',
        duration: 2000,
        center: true,
    })
}
let jse;
const getZTime = (data) => {
    if (!jse) {
        jse = new JSEncrypt(); // 实例化一个 jsEncrypt 对象
        jse.setPublicKey(pubKey); //配置公钥
    }
    let t = new Date().getTime();
    t = Math.floor(t / 1000);
    let z;
    if (!data || !Object.keys(data).length) {
        z = `[timestamp={{${t}}}]`;
    } else {
        z = returnKey(data);
    }

    const key = jse.encrypt(z);
    return key;
}


const returnKey = (obj) => {
    var a = ''
    for (var k in obj) {
        a += `[${k}={{${obj[k]}}}]`
    }
    return a;
}
let ydbLoad;

// 加载框
const _loading = (msg) => {
    ydbLoad = Loading.service({
        lock: true,
        text: msg || '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
}
// 隐藏加载框
const _loadingClose = () => {
    if (ydbLoad) {
        ydbLoad.close();
    }
}

const getKey = (key) => {
    let v = window.localStorage.getItem(key);
    if (!v) return v;
    if (v.indexOf('obj-') === 0) {
        v = v.slice(4);
        return JSON.parse(v);
    } else if (v.indexOf('str-') === 0) {
        return v.slice(4);
    } else {
        window.localStorage.removeItem(key);
        return '';
    }
}
const setKey = (key, v) => {
    if (v === undefined) {
        window.localStorage.removeItem(key);
    } else {
        if (typeof v == 'object') {
            v = JSON.stringify(v);
            v = 'obj-' + v;
        } else {
            v = 'str-' + v;
        }
        window.localStorage.setItem(key, v);
    }

}
const getParam = (type) => {
    // 获取url中的参数
    var url = window.location.href;
    var c = url.split('?')[1];
    if (c) {
        c = c.split('&');
    }
    var obj = {};
    if (c) {
        c.forEach(function(v, i) {
            var k = v.split('=');
            obj[k[0]] = k[1]
        });
    }
    if (!type) {
        type = 'id'
    }
    return obj[type];
}

/*
 * 需导出的方法
 * 多用于js中的以$开头,为避免与其他引入函数造成冲突, 准确而言以$y_开头
 * 多用于模板中则以普通函数形式
 * 对于内部需调用的函数则多在上面定义，一般不直接调用YDB
 */
const YDB = {
    /* 跳转类 */
    // 跳转
    /**
     *  @constructor
     *  @description 调用方法： this.to_url({url:'user/report', title:'举报', id: 1})
     */
    to_url: (data, url, login) => {
        if (data && typeof data == 'object' && Object.keys(data).length > 0) {
            let str = [];
            for (let p in data) {
                str.push(`${p}=${data[p]}`);
            }
            let _s = '?' + str.join('&');
            url = url ? url : 'index/win';
            url = str ? url + _s : url;
        }
        window.location.href = url;
    },
    _home: () => {
        window.location.href = home_url
    },

    /* 请求类 */
    /**
     * @constructor
     * @description 请求 urls:接口, data:参数, data.urlType:若有传参,但又需限制请求方式,则通过urlType设置
     * @example let ret = await this.$_ajax(url, data);
     *
     */
    $y_ajax: async (urls, data) => {
        urls = dealUrl(urls);
        let isLogin = checkLogin(urls);
        if (!isLogin) {
            _msg('请先登录')
            return {};
        }
        let type = data ? 'POST' : 'GET';
        // 若有传参,但又需限制请求方式
        if (data && data.urlType) {
            type = data.urlType;
            delete data.urlType;
        }
        let header = {
            'content-type': 'application/json'
        }
        if (getKey('token')) {
            header['Authorization'] = getKey('token')
        }
        var rsa_url = {
            'api/user/info': 'api/v2.user/info',
            'api/dymanic/lists': 'api/v2.dymanic/lists',
        }
        if (rsa_url[urls]) {
            urls = rsa_url[urls]
        }
        let params;
        // tp3接口存在问题 无法接收post的json数据
        if (rurl(urls).indexOf('apis') != -1) {
            header['content-type'] = 'application/x-www.form-urlencoded';
            // params = data;
            let formData = new FormData();
            for (let k in data) {
                formData.append(k, data[k]);
            }
            data = formData;
        }
        try {
            if (urls.indexOf('v2.') != -1) {
                type = 'POST';
                // header['content-type'] = 'application/x-www.form-urlencoded';
                let formData = new FormData();
                formData.append('z', getZTime(data));
                data = formData;
            }
            let ret = await axios({
                url: rurl(urls),
                method: type,
                data: data,
                headers: header,
                // params: params,
                // withCredentials: false, // 跨域请求时是否需要使用凭证
                responseType: "json",
            })
            if (ret.status == 200) {
                let res = ret.data;
                if (res.code == 402) {
                    _msg(res.msg);
                    setKey('userInfo', '')
                    setKey('userid', '');
                    setKey('token', '');
                    YDB._home();
                    return {};
                }
                return res;
            } else {
                // console.log(1)
                _msg('网络连接失败');
                return {};
            }
        } catch (error) {
            _loadingClose();
            _msg('网络连接失败');
            return {};
        }
    },

    // 图片上传
    $y_upImg: async (img) => {
        if (typeof img == 'string') {
            return img;
        }
        let formData = new FormData();
        formData.append("image", img);
        // formData.append("thumb", 1);
        if (getKey('userid')) {
            formData.append("water", 1);
        }
        let header = {
            'content-Type': 'application/x-www-form-urlencoded',
        }
        if (getKey('token')) {
            header['Authorization'] = getKey('token')
        }
        try {
            let ret = await axios({
                url: rurl('api/Tool/imagesUpload'),
                method: 'post',
                data: formData,
                headers: header,
                withCredentials: false, // 跨域请求时是否需要使用凭证
                // responseType: "json",
            })
            if (ret.status == 200) {
                let res = ret.data;
                if (res.code == 200) {
                    return res.data.image;
                } else {
                    _msg(res.msg);
                    return '';
                }
            } else {
                _loadingClose();
                _msg('网络连接失败');
                return '';
            }
        } catch (error) {
            _loadingClose();
            _msg('网络连接失败');
            return '';
        }

    },
    // 多图上传
    $y_upImgs: async (all) => {
        let num = []; // 记录原始的位置
        let formData = new FormData();
        let fi = 0;
        for (let i = 0, len = all.length; i < len; i++) {
            let img = all[i];
            if (typeof img != 'string') {
                num.push(i);
                formData.append(`image[${fi}]`, img);
                fi++;
            }
        }
        // formData.append("thumb", 1);
        if (getKey('userid')) {
            formData.append("water", 1);
        }
        if (num.length == 0) {
            return all;
        }
        let header = {
            'content-Type': 'application/x-www-form-urlencoded',
        }
        if (getKey('token')) {
            header['Authorization'] = getKey('token')
        }
        let ret = await axios({
            url: rurl('api/Tool/imagesUploads'),
            method: 'post',
            data: formData,
            headers: header,
            withCredentials: false, // 跨域请求时是否需要使用凭证
            // responseType: "json",
        })
        try {
            if (ret.status == 200) {
                let res = ret.data;
                if (res.code == 200) {
                    let res_img = res.data.images.split(',');
                    num.forEach((v, i) => {
                        if (res_img[i]) {
                            all[v] = res_img[i];
                        } else {
                            all[v] = '';
                        }
                    })
                    if (res_img.length != num.length) {
                        // 返回的数量与需要上传的不一致，则需去除上传为空的
                        all = all.filter((v, i) => {
                            return v;
                        })
                    }
                    return all.join(',');
                } else {
                    _msg(res.msg);
                    return '';
                }
            } else {
                _loadingClose();
                _msg('网络连接失败');
                return '';
            }
        } catch (error) {
            _loadingClose();
            _msg('网络连接失败');
            return '';
        }

    },
    // 视频上传
    $y_upVideo: async (img) => {
        if (typeof img == 'string') {
            return img;
        }
        let formData = new FormData();
        formData.append("video", img);
        let header = {
            'content-type': 'application/json'
        }
        if (getKey('token')) {
            header['Authorization'] = getKey('token')
        }
        try {
            let ret = await axios({
                url: rurl('api/Tool/videoUpload'),
                method: 'post',
                data: formData,
                headers: header,
                withCredentials: false, // 跨域请求时是否需要使用凭证
                responseType: "json",
            })

            if (ret.status == 200) {
                let res = ret.data;
                if (res.code == 200) {
                    return res.data.image;
                } else {
                    _msg(res.msg);
                    return '';
                }
            } else {
                _loadingClose();
                _msg('网络连接失败');
                return '';
            }
        } catch (error) {
            _loadingClose();
            _msg('网络连接失败');
            return '';
        }

    },
    /* 获取用户资料 */
    $y_getUser: async () => {
        let data = {
            user_id: getKey('userid'),
            longitude: getKey('lon') || '113.320234',
            latitude: getKey('lat') || '23.022241'
        }
        const res = await YDB.$y_ajax('api/user/info', data);
        if (res.code == 200 && res.result) {
            return res.result;
        } else {
            return '';
        }
    },
    /* 自定义函数 */
    /**
     * @property {String} url: 接口地址
     * @property {Number} first:是否为初始页
     * @property {Object}
     */
    // 列表
    // url: 接口地址，list 列表数据，data:参数
    $y_list: async (url, list, data) => {
        if (!data) {
            data = {};
        }
        let init = {
            // size: data.pagesize || data.pagecount || 20,
            size: data.pagesize || 20,
        }
        // 因该项目接口 分页数量参数 不统一，又经常必传 故将两参数都传入
        // if (!data.pagecount) {
        //     data.pagecount = init.size; // 分页数量
        // }
        if (!data.pagesize) {
            data.pagesize = init.size; // 分页数量
        }
        let ydbList = JSON.parse(JSON.stringify(list || []));
        if (ydbList.length == 0 || data.page == 1) {
            // 加载第一页 清空数据
            ydbList = [];
        }
        if (ydbList.length % init.size != 0) {
            console.log(url + '已为最后一页,请勿继续加载,目前总数为: ' + ydbList.length);
            return {
                list: ydbList,
                total: -1
            };
        }
        if (!data.page) {
            data.page = Math.floor(ydbList.length / init.size) + 1;
        }
        console.log('目前总数：' + ydbList.length + '， 将加载页码：' + data.page)

        let ret = await YDB.$y_ajax(url, data);
        ret = ret.ret ? ret.ret : ret;
        if (ret) {
            if (ret.data && ret.data.data) {
                ret.data = ret.data.data;
            }
            ret.result = ret.result || ret.data || [];
            ydbList = ydbList.concat(ret.result);
        }
        if (ret.paging && ret.paging.count && !ret.count) {
            ret.count = ret.paging.count
        }
        return {
            list: ydbList,
            total: Number(ret.count || 0)
        };
    },

    /* 处理&格式化等 */
    // 图片处理
    rImg: (url, re) => {
        if (url && (url.indexOf('disk/') != -1 || url.indexOf('public/style') != -1)) {
            if (url.indexOf('http') == -1) {
                // 在线地址  缺少http
                var reoss = getKey('imgurl') || imgurl
                var zmoss = getKey('zmoss') || reoss;
                if (re) {
                    return reoss + url;
                } else {
                    return zmoss + url;
                }
            } else {
                url = url.replace(/http(s)*:/i, '');
                return url;
            }
        } else {
            // 非在线地址 / 不缺少http前缀
            return url;
        }
    },
    // 大图处理
    rBigImg: (arr) => {
        arr = arr.map((v, i) => {
            return YDB.rImg(v);
        })
        return arr;
    },
    returnNum(n, type) {
        if (Number(n) != n) return n;
        if (n >= 10000) {
            var s = type ? '<span>万</span>' : 'w';
            n = (n / 10000).toFixed(2) + s
        } else if (n >= 1000) {
            var s = type ? '<span>千</span>' : 'k';
            n = (n / 1000).toFixed(2) + s
        }
        return n;
    },
    // 打开视频
    openVideo(v) {
        let v1 = v.toLowerCase();
        if (v1.indexOf('.mp4') == -1) {
            _msg('该视频不可播放')
            return;
        }
        window.open(YDB.rImg(v));
    },
    /* 提示&弹窗 */
    // 提示
    $y_msg: _msg,
    $y_loading: _loading,
    $y_loadingClose: _loadingClose,
    $y_alert: (msg, cb, title) => {
        MessageBox.alert(msg, title, {
            callback: cb
        })
    },
    $y_confirm: (data, cb, title) => {
        let confirmText = '确定';
        if (data.btns && data.btns[0]) {
            confirmText = data.btns[0];
        }
        let cancelText = '取消';
        if (data.btns && data.btns[1]) {
            cancelText = data.btns[1];
        }
        MessageBox.confirm(data.msg, title, {
            confirmButtonText: confirmText,
            cancelButtonText: cancelText,
        }).then(() => {
            cb(1)
        }).catch(action => {
            cb(0)
        });
    },
    $y_prompt: (data, cb, title) => {

        let confirmText = '确定';
        if (data.btns && data.btns[0]) {
            confirmText = data.btns[0];
        }
        let cancelText = '取消';
        if (data.btns && data.btns[1]) {
            cancelText = data.btns[1];
        }
        MessageBox.prompt(data.msg, title, {
            confirmButtonText: confirmText,
            cancelButtonText: cancelText,
            callback: cb,
            inputPlaceholder: data.input,
        })
    },
    // 关闭
    close: (wn, t) => {
        window.location.close()
    },
    winback: () => {
        window.history.back()
    },
    $y_getKey: getKey,
    $y_setKey: setKey,
    $y_rmKey: (key) => {
        window.localStorage.removeItem(key)
    },
    $y_device: (p) => {
        // 生成设备号
        let d = getKey('device');
        if (!d) {
            let t = new Date().getTime();
            d = Base64.encode(p + t);
            if (p.length == 11) {
                setKey('device', d);
            }
        }
        return d;
    },
    // 其他错误图片
    errImg: "this.src='" + errImg + "';this.onerror=null;",
    // 头像错误图片
    txImg: "this.src='" + txImg + "';this.onerror=null;",
    gzhImg: gzhImg,
    share_url: share_url,
    openApp: () => {
        let u = share_url;
        if (getKey('yq')) {
            u += '?yq=' + getKey('yq')
        }
        let w = window.innerWidth;
        var wu = window.location.href;
        let id = wu.split('?id=')[1];
        let uuid = wu.split('?uuid=')[1];
        if (id) {
            id = parseInt(id);
        }
        if (uuid) {
            uuid = parseInt(uuid);
        }
        if (w < 600 && (id || uuid)) {
            // 移动端打开app
            if (id) {
                wu = `relangchat://?type=4&id=${id}`;
            }
            if (uuid) {
                wu = `relangchat://?type=6&id=${uuid}`;
            }
            setTimeout(function() {
                wu = u;
            }, 3000)
        } else {
            window.open(u);
        }
    },
    goUser(id) {
        window.location.href = '#/user?uuid=' + id
    },
    setMeta(t, d) {
        if (!d) {
            d = t ? t : '这里是生活经验社区';
        }
        if (!t) {
            t = '热浪-生活经验'
        }
        if (d == 1) {
            d = '这里是生活经验社区'
        }
        document.querySelector("title").innerText = t;
        document.querySelector("meta[name=Description]").setAttribute("content", d);
    },
    clearHtml(s) {
        if (!s) {
            return s;
        }
        let dd = s.replace(/<\/?.+?>/g, "").replace(/  /g, "").replace(/\\n/g, "");
        return dd;
    },
    $y_setWebToken() {
        // app 跳转web获取token并设置信息
        let _t = getKey("token") || getParam('t');
        if (!_t) return;
        if (_t) {
            let param = _t.split('.')[1];
            if (!param) return;
            let res = Base64.decode(param);
            if (!res) return;
            if (typeof res == 'string') {
                res = JSON.parse(res);
            }
            res = res.data;
            if (res.userid) {
                setKey("token", _t);
                setKey("userid", res.userid);
                setKey("sex", res.sex);
            }
        }
    },
}

export default YDB;